import { ReactElement } from 'react';

import CTA from 'components/UI/Cta';
import { IComponentServicesGalleryFields } from 'types/contentful';
import Carousel from 'components/UI/Carousel';

export default function ServicesGallery({
  heading,
  gallery,
  cta,
}: IComponentServicesGalleryFields): ReactElement {
  const galleryItems = gallery.map((block) => block.fields);

  const items = galleryItems.slice(0, 8).map((service, index) => {
    const url = service.image?.fields.file.url ?? '/images/preview-placeholder.jpeg';
    return (
      <a href={service.link} key={index} className="service-item text-center">
        <div
          style={{ backgroundImage: `url(${url})` }}
          className="mb-2 bg-cover bg-center aspect-ratio-square"
        ></div>
        <div className="flex justify-between items-center">
          <span className="text-lg">{service.rowOneText ?? service.id}</span>
          <div className="hidden md:block">
            <img src="/images/chevron-right.svg" alt="chevron right" className="w-7 h-7" />
          </div>
        </div>
      </a>
    );
  });

  return (
    <div className="py-10 w-full md:w-3/4 mx-auto">
      <div className="text-3xl md:text-4xl text-center -mb-8 md:mb-8 text-secondary">{heading}</div>
      <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">{items}</div>
      <div className="md:hidden">
        <Carousel items={items ?? []} title={''} />
      </div>
      <div className="w-3/5 md:w-full mx-auto text-center -mt-8 md:mt-8">
        {cta?.fields && <CTA {...cta?.fields} classNames="button-text text-primary-actual" />}
      </div>
    </div>
  );
}
